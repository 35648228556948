.lottoCard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: none;
    border-radius: 25px;
    overflow: hidden;
    background-color: hsl(0, 0%, 90%);
}

.lottoCard .cardHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--blue1);
    padding: 0.5rem;
    gap: 0.25rem;
}

.lottoCard .cardHeader .h5 {
    flex: 1;
    padding: 0.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    border-radius: 25px;
    background-color: white;
    text-align: center;
    color: var(--blue1);
    font-weight: bold;
}

.lottoCard .contentCard {
    display: flex;
    gap: 1rem;
    padding: 0.5rem 1rem;
}

.lottoCard .contentCard>div {
    border-radius: 25px;
    background-color: white;
    overflow: hidden;
    flex: 1;
}

.lottoCard .contentCard .time {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
}