.sider {
    padding: 20px 5px 5px 5px;
    /* height: 100px; */
    text-align: center;
    color: #fff;
    font-size: 16px;
}

.content {
    padding: 1rem;
    /* margin: 20px; */
    background: #fff;
    min-height: 280px;
    border-radius: 5px;
}

.layout {
    min-height: 100vh;
}

.header {
    background: #fff;
    padding: 0px;
}

.user-detail {
    padding: 10px;
    color: #fff;
}

.user-detail img {
    max-width: 90%;
}

@media screen and (min-width: 375px) {
    .user-detail img {
        position: absolute;
        bottom: 0%;
    }
}

.ad {
    padding: 10px;
    color: #fff;
}

.ant-descriptions-row>td {
    padding-bottom: 0px;
}

.contact {
    position: absolute;
    bottom: 10px;
    padding: 10px;
    color: #fff;
}

ant-drawer-title {
    display: flex;
    justify-content: center;
}

.ant-drawer-body {
    background-color: var(--green1);
    overflow: visible;
}

.ant-drawer-header {
    background-color: var(--green1);
    border: none;
    padding: 0 1rem;
}


@media screen and (max-width: 375px) {
    .ant-drawer-wrapper-body {
        max-width: 100dvw;
    }
}